export enum VahaModel {
  VAHA_X = "vaha_x",
  VAHA_S = "vaha_s",
}

export enum VahaModelPaymentTotal {
  VAHA_X = 1950,
  VAHA_S = 1150,
}

export enum VahaModelPaymentMonthly {
  VAHA_X = 50,
  VAHA_S = 29.49,
}

export const VAHA_S_QUERY_PARAM = "vaha-s";
