










import { Component, Vue } from "vue-property-decorator";
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import { VahaModel, VAHA_S_QUERY_PARAM } from "@/utils/constants";

@Component({
  components: {
    Navigation,
    Footer,
  },
})
export default class App extends Vue {
  created() {
    this.setModel();
  }

  setModel() {
    let model = VahaModel.VAHA_X;
    // @ts-ignore
    if ((this.$route.query.model || "").toLowerCase() === VAHA_S_QUERY_PARAM) model = VahaModel.VAHA_S;

    this.$store.dispatch("setModel", model);
  }
}
