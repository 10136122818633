export default class Account {
  id: number | null = null;
  firstName = "";
  lastName = "";
  phoneNumber = "";
  email = "";
  phoneNumberCountryCode = "GB";
  phoneNumberCountrCallingCode = "44";

  constructor(attributes?: any) {
    if (attributes) Object.assign(this, attributes);
  }

  isValid(): boolean {
    return !!this.firstName && !!this.lastName && !!this.phoneNumber;
  }

  serializedAccountForZoho() {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phoneNumber: "+" + this.phoneNumberCountrCallingCode + this.phoneNumber,
    };
  }
}
