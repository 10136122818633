












































































import { Component, Vue } from "vue-property-decorator";
import Account from "@/data/account";
import EventBus from "@/event_bus";
import { validateEmail } from "@/utils/validations";
import PhoneNumber from "@/components/PhoneNumber.vue";
import { VahaModelPaymentTotal, VahaModelPaymentMonthly } from "@/utils/constants";

@Component({
  components: { PhoneNumber },
  computed: {
    account() {
      return this.$store.state.account;
    },
    privacyAccepted: {
      get() {
        return this.$store.state.privacyAccepted;
      },
      set(value) {
        this.$store.state.privacyAccepted = value;
      },
    },
    contactAccepted: {
      get() {
        return this.$store.state.contactAccepted;
      },
      set(value) {
        this.$store.state.contactAccepted = value;
      },
    },
  },
})
export default class ContactDetails extends Vue {
  private account: Account;
  private shouldShowErrors = false;
  private privacyAccepted: boolean;
  private contactAccepted: boolean;
  private isEmailValid = true;
  private isPhoneNrValid = false;
  private errors: any = {};

  get paymentOptionsText() {
    const selectedModel = this.$store.state.selectedModel.toUpperCase();
    const templateText = this.$t("CONTACT_DETAILS.TRY.THEN_PAY");

    // @ts-ignore
    const one = templateText.replace("{{month}}", `${VahaModelPaymentMonthly[selectedModel]}`);
    const two = one.replace("{{full}}", `${VahaModelPaymentTotal[selectedModel]}`);
    return two;
  }

  continueToConfirmation() {
    this.isEmailValid = validateEmail(this.account.email);
    if (
      this.isEmailValid &&
      this.isPhoneNrValid &&
      this.account.isValid() &&
      this.privacyAccepted &&
      this.contactAccepted
    ) {
      EventBus.trigger("goToStep", 1);
      return this.$store.dispatch("storeAccount", this.account);
    } else this.shouldShowErrors = true;
  }

  isInvalid(field: string): boolean {
    return !field && this.shouldShowErrors;
  }

  changePrivacyCheckbox(): void {
    this.privacyAccepted = !this.privacyAccepted;
  }

  changeContactCheckbox(): void {
    this.contactAccepted = !this.contactAccepted;
  }

  validateAccountEmail() {
    this.isEmailValid = this.shouldShowErrors ? validateEmail(this.account.email) : true;
  }

  onPhoneNumberErrors(errors: any) {
    this.errors.phoneNumber = errors;
  }

  onPhoneNumberUpdated(data: any) {
    const { phoneNumber, countryCode, countryCallingCode, isValid } = data;
    this.account.phoneNumber = phoneNumber;
    this.account.phoneNumberCountryCode = countryCode;
    this.account.phoneNumberCountrCallingCode = countryCallingCode;
    this.isPhoneNrValid = isValid;
  }
}
