





















































import { Component, Vue } from "vue-property-decorator";
import { LeadService } from "@/service/lead";
import Account from "@/data/account";
import { VahaModel } from "@/utils/constants";

@Component({
  computed: {
    selectedModel() {
      return this.$store.state.selectedModel;
    },
    account() {
      return this.$store.state.account;
    },
  },
})
export default class Confirmation extends Vue {
  private account: Account;
  private selectedModel: VahaModel;

  created() {
    if (process.env.NODE_ENV === "development") return;

    this.addToDataLayer();
    const account = this.account.serializedAccountForZoho();
    const body = { ...account, vahaModel: this.selectedModel.toUpperCase() };
    LeadService.createLead(body).then((leadId) => {
      this.account.id = leadId;
      return this.$store.dispatch("storeAccount", this.account);
    });
  }

  addToDataLayer() {
    // @ts-ignore
    window.dataLayer.push({ event: "orderSummaryUKLead" });
  }

  mounted() {
    window.scrollTo(0, 0);
  }

  scrollToVideo() {
    const element = document.getElementsByTagName("video")[0];
    if (element) element.scrollIntoView({ behavior: "smooth" });
  }
}
