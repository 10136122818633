








































import { Component, Vue } from "vue-property-decorator";
import EventBus from "@/event_bus";

type NavigationStep = {
  name: string;
  path: string;
};

@Component
export default class Navigation extends Vue {
  steps: NavigationStep[] = [
    { name: "NAVIGATION.CONTACT_DETAILS", path: "contact-details" },
    { name: "NAVIGATION.CONFIRMATION", path: "confirmation" },
  ];
  currentStepIndex = 0;

  created(): void {
    EventBus.on("goToStep", this.goToStep);
    this.goToStep(0);
  }

  beforeDestroy(): void {
    EventBus.off("goToStep", this.goToStep);
  }

  goToStep(index: number): void {
    if (this.steps[index] && index !== this.currentStepIndex) {
      this.currentStepIndex = index;
      this.$router.push({
        path: `/${this.steps[index].path}`,
        query: this.$route.query,
      });
    }
  }

  goToPreviousStep(): void {
    this.goToStep(this.currentStepIndex - 1);
  }
}
