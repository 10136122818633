import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
// @ts-ignore
import { fetchDefaultLocale } from "./../i18n-setup";

Vue.config.productionTip = false;
Vue.use(VueMaterial);

fetchDefaultLocale().then((i18n: any) => {
  new Vue({
    router,
    store,
    // @ts-ignore
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
});
