












































































































import { Component, Vue } from "vue-property-decorator";

const CountryAccronims = {
  uk: "United Kingdom",
};

@Component({
  computed: {
    country() {
      return this.$store.state.country;
    },
    countryFullName() {
      return CountryAccronims[this.$store.state.country];
    },
  },
})
export default class Footer extends Vue {}
