import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import ContactDetails from "@/views/ContactDetails.vue";
import Confirmation from "@/views/Confirmation.vue";
import store from "../store/index";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/contact-details",
    name: "ContactDetails",
    component: ContactDetails,
  },
  {
    path: "/confirmation",
    name: "Confirmation",
    component: Confirmation,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if ((to.path === "/confirmation" && store.state.privacyAccepted) || to.path === "/contact-details") {
    next();
  } else {
    next({ path: "/contact-details", query: to.query });
  }
});

export default router;
