import axios from "axios";

export const LeadService = {
  createLead: (body: any): Promise<number | null> => {
    const apiClient = axios.create({ baseURL: "" });
    return apiClient
      .post("/api/leads", body)
      .then((response: any) => {
        if (response && response.data) return Promise.resolve(response.data.id);
        return Promise.resolve(null);
      })
      .catch(() => Promise.resolve(null));
  },
};
