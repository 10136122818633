type EventName = "goToStep";

class EventBus {
  private handlers: { [key: string]: Array<any> } = {};

  on(eventName: EventName, handler: any) {
    this.handlers[eventName] = (this.handlers[eventName] || []).concat([handler]);
  }

  off(eventName: EventName, handler?: any) {
    if (handler) {
      this.handlers[eventName] = this.handlers[eventName].filter((x) => x !== handler);
    } else {
      this.handlers[eventName] = [];
    }
  }

  trigger(eventName: EventName, ...args: any) {
    for (const fun of this.handlers[eventName] || []) {
      fun(...args);
    }
  }
}

export default new EventBus();
