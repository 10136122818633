import Vue from "vue";
import Vuex from "vuex";
// @ts-ignore
import { loadLanguageAsync } from "../../i18n-setup";
import Account from "@/data/account";
import { VahaModel, VahaModelPaymentTotal } from "@/utils/constants";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    locale: "en",
    country: "uk",
    account: new Account(),
    privacyAccepted: false,
    contactAccepted: false,
    selectedModel: VahaModel.VAHA_X,
    selectedModalPaymentTotal: VahaModelPaymentTotal.VAHA_X,
  },
  mutations: {
    changeLanguage(state, value) {
      state.locale = value;
    },
    storeAccount: (state, account: Account) => {
      state.account = account;
    },
    setModel: (state, value: VahaModel) => {
      state.selectedModel = value;
      state.selectedModalPaymentTotal = VahaModelPaymentTotal[value.toUpperCase()];
    },
  },
  actions: {
    async changeLanguage(context, lang) {
      await loadLanguageAsync(lang);
      context.commit("changeLanguage", lang);
    },
    storeAccount: (context, account: Account) => {
      context.commit("storeAccount", account);
    },
    setModel: (context, value: VahaModel) => {
      context.commit("setModel", value);
    },
  },
  modules: {},
});
