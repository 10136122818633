



















import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

Vue.component("vue-phone-number-input", VuePhoneNumberInput);

@Component({
  components: { VuePhoneNumberInput },
})
export default class CountryCodePicker extends Vue {
  @Prop({ required: true })
  numberProp: string;

  @Prop({ required: true })
  countryCodeProp: string;

  @Prop({ required: true })
  showError: boolean;

  @Watch("showError")
  onShowError(newValue: boolean) {
    if (newValue) {
      // @ts-ignore
      this.phoneNumberInput.placeholder = `${this.$t("CONTACT_DETAILS.PHONE_NUMBER")}*`;
      this.phoneNumberInput.classList.add("placeholder-error");
    } else {
      // @ts-ignore
      this.phoneNumberInput.placeholder = this.$t("CONTACT_DETAILS.PHONE_NUMBER");
      this.phoneNumberInput.classList.remove("placeholder-error");
    }
  }

  @Emit() private update(e: any) {
    this.isValid = e.isValid;
    this.countryCode = e.countryCode;
    this.countryCallingCode = e.countryCallingCode;
    this.phoneNumber = e.phoneNumber;
    return {
      phoneNumber: this.phoneNumber,
      countryCode: this.countryCode,
      countryCallingCode: this.countryCallingCode,
      isValid: this.isValid,
    };
  }

  private vuePhoneNumber: HTMLElement;
  private phoneNumberInput: HTMLElement;
  private phoneNumber: null | string = null;
  private countryCode: null | string = null;
  private countryCallingCode: null | string = null;
  private phoneNumberFocused = false;
  private isValid = false;

  created() {
    if (this.numberProp) this.phoneNumber = this.numberProp;
    if (this.countryCodeProp) this.countryCode = this.countryCodeProp;
  }

  mounted() {
    this.vuePhoneNumber = this.$refs["vue-phone-number"] as HTMLElement;
    this.phoneNumberInput = this.vuePhoneNumber["$children"][1]["$el"].children[0];
  }

  onFocus() {
    this.phoneNumberFocused = true;
    this.phoneNumberInput.classList.add("placeholder-slide");
  }

  onBlur() {
    this.phoneNumberFocused = false;
    this.phoneNumberInput.classList.remove("placeholder-slide");
  }
}
